<template>
  <!-- ERROR -->
  <template v-if="agid">
    <div class="row ic1-centered">
      <div class="ic1-col-6 ic1-card-error">
        <h2 class="ic1-text-heading-2">{{ getErrorTitle(errorTitle) }}</h2>
        <p class="ic1-text-subtitle-2">{{ getErrorMessage(errorMessage) }} </p>
        <p class="ic1-text-subtitle-2" v-if="errorCodeRef && transactionId">{{ getErrorCodeRef(errorCodeRef, transactionId) }} </p>
      </div>
    </div>
  </template>

  <template v-else>
    <div class="grid">
      <div class="width-one-whole">
        <div class="alert alert-error" role="alert">
          <div class="alert-body">
            <h3 class="alert-heading" data-i18n="basicauth.label_error">
              {{ getErrorTitle(errorTitle) }}
            </h3>
            <p
              class="alert-text"
              data-i18n="[html]credential_state.INVALID"
              data-i18n-options='{ "url": "" }'
            >
              {{
                getErrorMessage(errorMessage)
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </template>

</template>
<script>
import {useI18n} from "vue-i18n";
import {ref} from "vue";
import {getCookie} from "@/utils/useCookie";
import {environment} from "@/config";

export default {
  data() {
    return {
      errorMessage: null,
      errorTitle: null,
      errorCodeRef: null,
      transactionId: null
    }
  },
  computed: {
    agid() {
      return process.env.VUE_APP_SUB_PATH !== "tim";
    },
  },
  setup() {
    const {t, locale} = useI18n();
    const count = ref(0);
    return {count, t, locale};
  },
  beforeMount() {
    const errorCode = this.getParam('errorCode');
    this.errorCodeRef = "SPID.ERROR.CODEREF"
    this.transactionId = getCookie('transactionId');
    console.log('errorCode by param:' + errorCode);
    switch (Number(errorCode)) {
      case 2:
        this.errorTitle = "SPID.ERROR.TITLE.GENERIC";
        this.errorMessage = "SPID.ERROR.MESSAGE.UNKNOWN";

        break;
      case 3:
        this.errorTitle = 'SPID.ERROR.TITLE.SYSTEM';
        this.errorMessage = "SPID.ERROR.MESSAGE.UNKNOWN";
        break;
      case 4:
        this.errorTitle = 'SPID.ERROR.TITLE.BINDING';
        this.errorMessage = "SPID.ERROR.MESSAGE.BINDING";
        break;
      case 5:
        this.errorTitle = 'SPID.ERROR.TITLE.SIGN';
        this.errorMessage = "SPID.ERROR.MESSAGE.SIGN";
        break;
      case 6:
        this.errorTitle = 'SPID.ERROR.TITLE.BINDING_HTTP';
        this.errorMessage = "SPID.ERROR.MESSAGE.BINDING_HTTP";
        break;
      case 7:
        this.errorTitle = 'SPID.ERROR.TITLE.REQUESTED_SIGN';
        this.errorMessage = "SPID.ERROR.MESSAGE.REQUESTED_SIGN";
        break;
      case 10:
        this.errorTitle = 'SPID.ERROR.TITLE.ISSUER';
        this.errorMessage = "SPID.ERROR.MESSAGE.ISSUER";
        break;
      case 11:
        this.errorTitle = 'SPID.ERROR.TITLE.DATE';
        this.errorMessage = "SPID.ERROR.MESSAGE.DATE";
        break;

      default:
        this.errorTitle = "SPID.ERROR.TITLE.GENERIC";
        this.errorMessage = "SPID.ERROR.MESSAGE.UNKNOWN";
    }
  },
  methods: {
    getErrorTitle(errorTitle) {
      console.log('getErrorTitle:' + errorTitle);
      return this.t(errorTitle, {}, {locale: this.locale});
    },
    getErrorMessage(errorMessage) {
      return this.t(errorMessage, {}, {locale: this.locale});
    },
    getErrorCodeRef(errorCodeRef, transactionId) {
      if(transactionId.includes(" ") || transactionId.includes("http") || transactionId.includes("https")){
        transactionId = "";
      }

      return this.t(errorCodeRef, {}, {locale: this.locale}) + transactionId;
    },
    getParam(name) {
      let urlParams = new URLSearchParams(window.location.search);
      let myParam = urlParams.get(name);
      return myParam;
    }
  }
}
</script>
